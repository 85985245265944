import axios from 'axios';
import { Api } from './api';

const headers = {
  'access-key': import.meta.env.VITE_APP_LAMBDA_ACCESS_KEY,
  token: localStorage.getItem('st-token'),
};

const ROOT_URL = import.meta.env.VITE_APP_ST_API;

function getTodaysDate() {
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const currentDate = `${day}_${month}_${year}`;

  return currentDate;
}

function saveToken(response) {
  if (response.status !== 200) return;
  if (response.data?.access_token) localStorage.setItem('st-token', response.data.access_token);
}

// Check to see if the project is from ServiceTitan based of if the alias converts to a number
function isSTAlias(alias) {
  const parsedAlias = parseInt(alias, 10);

  if (isNaN(parsedAlias)) return false;
  return parsedAlias;
}

export async function sendNotesST(STNote, alias, user) {
  if (!isSTAlias(alias)) return;

  const dataToSend = {
    text: `${STNote.STNote}`,
    pinToTop: false,
  };

  const response = await axios.post(`${ROOT_URL}notes?tenantId=${user.st_tenant_id}&jobId=${alias}`, dataToSend, {
    headers,
  });
  saveToken(response);
}

export function sendReportST(projectId, createdReport, alias, user) {
  if (!isSTAlias(alias)) return;

  let reports;
  let matchedReport;

  setTimeout(async () => {
    reports = await Api.get(`projects/${projectId}/reports`, {
      params: {
        include: 'creator',
        limit: 5,
        sort: '-created_at',
      },
    });

    matchedReport = reports.data?.data?.find((report) => report.id === createdReport.data?.id);

    if (matchedReport.status !== 'Completed') return sendReportST(projectId, createdReport, alias, user);

    const pdfName = `${matchedReport.name}_${getTodaysDate()}.pdf`;

    const response = await axios.post(
      `${ROOT_URL}reports?tenantId=${user.st_tenant_id}&jobId=${alias}`,
      { pdfUrl: matchedReport.pdf_url, pdfName },
      { headers }
    );

    return saveToken(response);
  }, 1000);
}

export async function sendPhotosST(chunkedArr, alias, photoNameInfo, user) {
  try {
    if (!isSTAlias(alias)) return;

    const fileName = `${photoNameInfo.address}-${photoNameInfo.name}-${
      photoNameInfo.room.room_type.name
    }-${photoNameInfo.album.name.replace(' ', '_')}-${getTodaysDate()}`;

    chunkedArr.forEach((chunk) => {
      const formData = new FormData();

      chunk.forEach((file) => {
        const fileNameArr = file.name.split('.');
        const ext = fileNameArr[fileNameArr.length - 1];
        formData.append('file', file, `${fileName}.${ext}`);
      });

      axios.post(`${ROOT_URL}attachments?tenantId=${user.st_tenant_id}&jobId=${alias}`, formData, { headers });
    });

    // Might not need to wait for the response
    // const formsPromise = chunkedArr.map((chunk) => {
    //   const formData = new FormData();

    //   chunk.forEach((file) => {
    //     formData.append('file', file);
    //   });

    //   return axios.post(`${ROOT_URL}/attachments?jobId=${alias}`, formData, { headers });
    // });
    // await Promise.all(formsPromise);
  } catch (err) {
    // handle Error
  }
}

// setup methods for external use of Axios
import axios from 'axios';

import { cookieExists } from 'Utils/cookies';

// axios global
export const baseURL = import.meta.env.VITE_APP_API_URL;
export const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

// axios api instance
const instance = axios.create({
  baseURL,
  headers,
  withCredentials: true,
  withXSRFToken: true,
});

// making the api request
const request = async (url: string, requestData: object = {}, requestType = 'get', options = {}) =>
  await instance[requestType](url, requestData, options);

// TODO: remove after the timecard export csv issue is fixed in the backend
export const csvHeaders = {
  'Content-Type': 'application/json',
  Accept: 'text/csv;charset=UTF-8',
};

const csvInstance = axios.create({
  baseURL,
  headers: csvHeaders,
  withCredentials: true,
});

const csvRequest = async (url: string, requestData: object = {}, requestType = 'get', options = {}) =>
  await csvInstance[requestType](url, requestData, options);

const csvGet = async (url: string, params = {}) => await csvRequest(url, params);

// Perform a get and return the data
const get = async (url: string, params = {}) => await request(url, params);
const post = async (url: string, data: object, options?: object) => await request(url, data, 'post', options);
const put = async (url: string, data: object, options?: object) => await request(url, data, 'put', options);

// delete is a key word and cannot be used in strict mode. Hence deleteFn
const deleteFn = async (url: string, data = {}, options = {}) => await request(url, data, 'delete', options);
// Set the Authorization token
const setAuthorizationToken = (token: string = '') => {
  instance.defaults.headers.Authorization = `Bearer ${token}`;
};

// Remove the Authorization token
const resetAuthorizationToken = () => {
  // enable this to have a new token on each login
  delete instance?.defaults?.headers?.Authorization;
};

// Get the initial headers and cookies setup for csrf
const csrfHeader = async () => {
  await request(import.meta.env.VITE_APP_SANCTUM_URL);
  // Check for the cookie XSRF-TOKEN
  return cookieExists('XSRF-TOKEN');
};

export const Api = {
  get,
  post,
  put,
  delete: deleteFn,
  setAuthorizationToken,
  resetAuthorizationToken,
  csrfHeader,
  csvGet,
};
